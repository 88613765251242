import React from 'react';
import clsx from 'clsx';
import {FaFacebookF} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import {FaLinkedinIn} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    items: {
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        padding: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    icon: {
        fontSize: 20
    },
    light: {
        color: theme.palette.primary.contrastText,
        borderColor: `${theme.palette.primary.contrastText}`
    }
}));

let socials = [
    {
        title: 'Facebook',
        icon: FaFacebookF,
        url: 'https://www.facebook.com/negocioseguro/'
    },
    {
        title: 'Instagram',
        icon: FaInstagram,
        url: 'http://www.instagram.com'
    },
    {
        title: 'Linkedin',
        icon: FaLinkedinIn,
        url: 'https://br.linkedin.com/in/dr-júnio-marcos-andrade-de-sousa-matos-6ba9a615b'
    },
    {
        title: 'Twitter',
        icon: FaTwitter,
        url: 'https://twitter.com/junioaguia1'
    }
];

export default function Socials({light, noTwitter}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {
                socials && socials.map((item, index) => {
                    return <a href={item.url} target="_blank" rel="noopener noreferrer" className={clsx(classes.items, {[classes.light]: light})} key={index}>
                        <item.icon className={clsx(classes.icon, {[classes.light]: light})} />
                    </a>
                })
            }
        </div>
    );
}

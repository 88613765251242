import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/logoBlur.png';
import { withRouter } from 'react-router-dom';
import constants from '../../constants/index';
import eagle from '../../assets/images/eaglebg.jpg';
import './style/index.css';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => createStyles({
    hero: {
        zIndex: 0,
        display: 'flex',
        alignItems: 'center',
        minHeight: 'calc(100vh - 112px)',
        width: '100%',
        background: `url(${eagle})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        color: theme.palette.primary.dark,
        overflow: 'hidden'
    },
    cover: {
        zIndex: 1,
        width: '100%',
        minHeight: 'calc(100vh - 112px)',
        backgroundColor: 'rgba(51, 84, 132, 0.5)',
        margin: 0
    },
    centerize: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    title: {
        padding: theme.spacing(3),
        textAlign: 'center'
    },
    sectionTableOne: {
        backgroundColor: theme.palette.primary.dark,
        flexBasis: '33.33%',
        flexGrow: 1,
        padding: theme.spacing(6),
        textAlign: 'center',
        cursor: 'pointer'
    },
    sectionTableTwo: {
        backgroundColor: theme.palette.primary.main,
        flexBasis: '33.33%',
        flexGrow: 1,
        padding: theme.spacing(6),
        textAlign: 'center',
        cursor: 'pointer'
    },
    sectionTableThree: {
        backgroundColor: theme.palette.primary.dark,
        flexBasis: '33.33%',
        flexGrow: 1,
        padding: theme.spacing(6),
        textAlign: 'center',
        cursor: 'pointer'
    },
    partialSection: {
        width: '100%'
    },
    actionButton: {
        color: theme.palette.primary.constrastText,
        margin: `0px ${theme.spacing(2)}px`
    },
    verticalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    horizontalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    }
}));

function Hero({ history }) {
    const classes = useStyles();

    return (
        <div className={classes.hero}>
            <Grid container spacing={3} className={classes.cover}>
                <Grid item xs={12} md={8} className={classes.centerize}>
                    <div className={classes.verticalWrapper}>
                        <Typography variant="h1">
                            <span onClick={() => history.push(constants.consultoria)} style={{ cursor: 'pointer' }}>Consultoria e Assessoria</span>
                            <span onClick={() => history.push(constants.treinamento)} style={{ cursor: 'pointer' }}>Treinamento</span>
                            <span onClick={() => history.push(constants.institucional)} style={{ cursor: 'pointer' }}>Corretora de seguros</span>
                            <span onClick={() => history.push(constants.documentoscopia)} style={{ cursor: 'pointer' }}>PERICIA JUDICIAL GRAFOTÉCNICA E DOCUMENTOSCOPIA</span>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} className={classes.verticalWrapper}>
                    <div className={classes.horizontalWrapper}>
                        <img src={logo} alt="Logo da JM Andrade"/>
                        <Typography style={{color: "#fff"}} variant="h6">"Sua segurança em nossas mãos"</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default withRouter(Hero);


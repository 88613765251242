import React, { useState } from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CustomMessage from '../CustomMessage/index';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DownIcon from '@material-ui/icons/ArrowDownward';
import UpIcon from '@material-ui/icons/ArrowUpward';
import ReplyIcon from '@material-ui/icons/Reply';
import DeleteIcon from '@material-ui/icons/Delete';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import avatar from '../../assets/images/avatar.png';
import axios from '../../axios';
import { getCurrentUser } from '../../services/Auth';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex'
    },
    image: {
        position: 'relative',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2)
    },
    cardContentAlign: {
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: '100%'
    },
    repliesAlign: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    replyAction: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        margin: theme.spacing(1)
    },
    replies: {
        display: 'flex',
        marginLeft: theme.spacing(2)
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    textField: {
        width: `calc(100% - (40px + ${theme.spacing(2)}px))`
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    container: {
        width: '100%'
    }
}));

export default function Comment({ comment, id, reload }) {
    const [showingReplies, setShowReplies] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [displayDrawer, setDisplayDrawer] = useState(false);
    const [showReplyField, setShowReplyField] = useState(false);
    const [commentContent, setCommentContent] = useState('');

    function handleShowReplyField() {
        console.log(showReplyField);
        setShowReplyField(!showReplyField);
    }

    function handleShowReplies() {
        setShowReplies(true);
    }

    function handleCloseReplies() {
        setShowReplies(false);
    }

    function toggleDrawer(state) {
        setDisplayDrawer(state);
        setShowReplies(state);
    }

    function handleChangeComment(event) {
        setCommentContent(event.target.value);
    }

    function handleResetInput() {
        setCommentContent('');
    }

    async function replyComment() {
        console.log(comment);
        const data = {
            content: commentContent,
            author: getCurrentUser().id,
            authorName: getCurrentUser().name,
            article: id,
            reply: comment.id
        }

        try {
            console.log(data);
            const response = await axios.post(`api/index.php/article/${comment.id}/reply`, { ...data });
            reload(false);
            setSuccess("Resposta postada !");
        } catch (error) {
            setError("Não foi possível responder a este comentário !");
        }
    }

    async function deleteComment(reply = false) {
        console.log(reply);
        try {
            const response = await axios.delete(`/api/index.php/article/${id}/comment/${reply ? reply : comment.id}`);
            setSuccess("Comentário apagado !");
            reload(response);
        } catch (error) {
            setError("Erro ao apagar comentário");
        }
    }

    const classes = useStyles();
    return <>
        {
            success && <CustomMessage type="success" message={success} handleClose={() => setSuccess(false)} open={success ? true : false} />
        }
        {
            error && <CustomMessage type="error" message={error} handleClose={() => setError(false)} open={error ? true : false} />
        }
        <Card elevation={0}>
            <CardContent className={classes.cardContentAlign}>
                <div>
                    <img src="https://jmandrade.com.br/static/media/avatar.png" className={classes.image} alt={comment.author + 'comentário'} />
                </div>
                <div className={classes.container}>
                    <p className={classes.authorTitle}><b>{comment.author_name}</b> - {comment.created_at}</p>
                    <p>{comment.content}</p>
                    <div className={classes.actions}>
                        {
                            showingReplies ? <>
                                <Button className={classes.sectionDesktop} color="primary" onClick={handleCloseReplies}>
                                    Ocultar Respostas <UpIcon fontSize="small" />
                                </Button>
                                <Button className={classes.sectionMobile} color="primary" onClick={() => toggleDrawer(!displayDrawer)}>
                                    Ocultar Respostas <DownIcon fontSize="small" />
                                </Button>
                            </> : <>
                                    <Button className={classes.sectionDesktop} color="primary" onClick={handleShowReplies}>
                                        Mostrar Respostas <DownIcon fontSize="small" />
                                    </Button>
                                    <Button className={classes.sectionMobile} color="primary" onClick={() => toggleDrawer(!displayDrawer)}>
                                        Mostrar Respostas <UpIcon fontSize="small" />
                                    </Button>
                                </>
                        }
                        <div className={classes.replyAction}>
                            {
                                getCurrentUser() && <IconButton onClick={() => handleShowReplyField()} aria-label="reply">
                                    <ReplyIcon />
                                </IconButton>
                            }
                            {
                                getCurrentUser() && getCurrentUser().id === comment.author && <IconButton aria-label="delete" onClick={() => deleteComment()}>
                                    <DeleteIcon />
                                </IconButton>
                            }

                        </div>
                    </div>
                    <hr />
                    {
                        showReplyField && <div>
                            <div className={classes.commentAlign}>
                                <img src="https://jmandrade.com.br/static/media/avatar.png" className={classes.image} alt={'foto de perfil'} />
                                <TextField
                                    id="standard-multiline-flexible"
                                    multiline
                                    placeholder="Insira um comentário"
                                    rowsMax="4"
                                    value={commentContent}
                                    onChange={e => handleChangeComment(e)}
                                    className={classes.textField}
                                    margin="normal"
                                />
                            </div>
                            <div className={classes.replyAction}>
                                <Button className={classes.button} size="medium" onClick={handleResetInput}>Cancelar</Button>
                                <Button className={classes.button} size="medium" variant="contained" color="primary" disabled={commentContent.length === 0} onClick={replyComment}>Responder</Button>
                            </div>
                        </div>
                    }
                    <div className={clsx(classes.replies, classes.sectionDesktop)} style={{ display: showingReplies ? 'block' : 'none' }}>
                        {
                            comment.replies && comment.replies.map((reply, index) => {
                                return <Card key={index} elevation={0}>
                                    <CardContent className={classes.cardContentAlign}>
                                        <div>
                                            <img src="https://jmandrade.com.br/static/media/avatar.png" className={classes.image} alt={reply.author + 'comentário'} />
                                        </div>
                                        <div>
                                            <p className={classes.authorTitle}>
                                                <b>{reply.author_name}</b> - {reply.created_at} {
                                                    getCurrentUser() && getCurrentUser().id === reply.author && <IconButton aria-label="delete" onClick={() => deleteComment(reply.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            </p>
                                            <p>{reply.content}</p>
                                        </div>

                                    </CardContent>
                                </Card>
                            })
                        }
                    </div>
                    <div className={clsx(classes.replies, classes.sectionMobile)}>
                        <Drawer anchor="bottom" open={displayDrawer} onClose={() => toggleDrawer(false)}>
                            <div className={classes.repliesAlign}>
                                <div className={classes.actions}>
                                    <Typography style={{ padding: '20px' }} variant="h6">{`Respostas`}</Typography>
                                    <IconButton style={{ marginRight: '20px' }} aria-label="Fechar Respostas" onClick={() => toggleDrawer(false)} >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                {
                                    comment.replies && comment.replies.map((reply, index) => {
                                        return <Card key={index} elevation={0}>
                                            <CardContent className={classes.cardContentAlign}>
                                                <div>
                                                    <img src="https://jmandrade.com.br/static/media/avatar.png" className={classes.image} alt={reply.author} />
                                                </div>
                                                <div>
                                                    <p className={classes.authorTitle}>
                                                        <b>{reply.author_name}</b> - {reply.created_at} {
                                                            getCurrentUser() && getCurrentUser().id === reply.author && <IconButton aria-label="delete" onClick={() => deleteComment(reply.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        }
                                                    </p>
                                                    <p>{reply.content}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    })
                                }
                            </div>
                        </Drawer>
                    </div>
                </div>
            </CardContent>
        </Card>
    </>
}



import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import './slick/slick.css'
import './slick/slick-theme.css'
import './style/index.css'
import Section from '../Section/index'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Slider from 'react-slick'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => createStyles({
  card: {
    maxWidth: '380px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  },
  cardImg: {
    width: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    height: '56px'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  linkWrapper: {
    textDecoration: 'none'
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
    textAlign: 'center'
  }
}))

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

function GenericCarousel ({ title, data, constrastBackground }) {
  const classes = useStyles()
  return (
    <Section noFullHeight constrastBackground={constrastBackground || false}>
      {
                title && <Typography variant='h6' className={classes.title}>{title}</Typography>
            }
      <Slider {...settings}>
        {
                    data && data.map((partner, index) => {
                      return (
                        <a key={index} href={partner.url} target='_blank' rel='noopener noreferrer' className={classes.linkWrapper}>
                          <Tooltip placement='bottom' interactive title={partner.name}>
                            <Card className={classes.card}>
                              <CardContent className={classes.cardContent}>
                                <img className={classes.cardImg} src={partner.img} alt={partner.name} />
                              </CardContent>
                            </Card>
                          </Tooltip>
                        </a>
                      )
                    })
                }
      </Slider>
    </Section>
  )
}

GenericCarousel.propTypes = {
  data: PropTypes.array
}

export default GenericCarousel

import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Socials from '../Socials/index';
import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { FaRegClock } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaShieldAlt } from 'react-icons/fa';
import constants from '../../constants/index';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import lattes from '../../assets/images/lattes-icone.png';

const useStyles = makeStyles(theme => createStyles({
    top: {
        width: '100%',
        backgroundColor: `${theme.palette.primary.contrastText}!important`,
    },
    root: {
        display: 'flex',
        padding: theme.spacing(10),
        backgroundColor: `${theme.palette.primary.contrastText}!important`,
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: 20,
        marginRight: theme.spacing(2)
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: theme.spacing(2)
    },
    bottomGrid: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        width: '100%',
        padding: theme.spacing(1)
    },
    copyright: {
        textAlign: 'center',
        color: 'white'
    },
    spacing: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <div className={classes.top}>
            <Container noSpacing={true} className={classes.root}>
                <Grid container>
                    <Grid item xs={12} md={4} className={classes.spacing}>
                        <Typography className={classes.title} variant="h6">Fale Conosco</Typography>
                        <div className={classes.wrapper}>
                            <div className={classes.item}><FaPhone className={classes.icon}></FaPhone>(62) 3952-3939</div>
                            <div className={classes.item}><FaWhatsapp className={classes.icon}></FaWhatsapp>(62) 99932-7070</div>
                            <div className={classes.item}><FaEnvelope className={classes.icon}></FaEnvelope> <a className={classes.link} href="mail:junio@jmandrade.com.br">junio@jmandrade.com.br</a> </div>
                            <div className={classes.item}><FaShieldAlt className={classes.icon}></FaShieldAlt><Link className={classes.link} style={{ textDecoration: 'none' }} to={constants.privacidade}>Política de Privacidade</Link></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.spacing}>
                        <Grid container>
                            <Grid item xs>
                                <Typography className={classes.title} variant="h6">Siga-nos</Typography>
                                <div className={classes.wrapper}>
                                    <Socials />
                                </div>
                                <div>
                                <p style={{display: 'flex', alignItems: 'center'}}>Currículo Lattes: <a rel="noopener noreferrer" href="http://lattes.cnpq.br/4985435033950204" target="_blank"><img style={{marginLeft: '10px', width:'40px', height: 'auto'}} src={lattes} alt="Júnio Marcos Andrade de Sousa Matos Lattes"/></a></p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.spacing}>
                        <Typography className={classes.title} variant="h6">Localização</Typography>
                        <div className={classes.wrapper}>
                            <div className={classes.item}><FaMapMarkedAlt className={classes.icon}></FaMapMarkedAlt>Rua T-51 Qd. L-27 Lt. 01-A Nrº 24 Casa 02 Setor Marista Goiânia-GO – Brasil CEP 74.150-160</div>
                            <div className={classes.item}><FaRegClock className={classes.icon}></FaRegClock>Seg a Sex das 08:00 às 18:00</div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div className={classes.bottomGrid}>
                <p className={classes.copyright}>&copy; {new Date().getFullYear()} JM Andrade - CPNJ: 19.576.245/0001-90</p>
                <p className={classes.copyright}>Todos os Direitos Reservados - Desenvolvido por <a target="_blank" rel="noopener noreferrer"  href="https://gabrielcosta.dev" style={{color: 'white'}}>Gabriel Costa</a></p>
            </div>
        </div>
    );
}

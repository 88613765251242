import axios from 'axios';
import constants from './constants/index';
import {getToken} from './services/Auth';

const instance = axios.create({
    baseURL: constants.baseUrl
});

instance.headers = {
    "Content-Type": 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*"
}

instance.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// instance.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     return Promise.reject(error.response.data.error);
// });

export default instance;
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Socials from '../Socials/index';
import Typography from '@material-ui/core/Typography';
import { FaWhatsapp } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark,
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    phones: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        minWidth: '165px',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px'
        }
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));



const whats = '(62) 99932-7070';
const phone = '(62) 3952-3939';

export default function Topbar() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Socials noTwitter light />
            <div className={classes.phones}>
                <Typography className={classes.item}><FaWhatsapp className={classes.icon} />{whats}</Typography>
                <Typography className={classes.item}><FaPhone className={classes.icon} />{phone}</Typography>
            </div>
        </div>
    );
}

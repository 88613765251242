import React, { useState } from 'react';
import clsx from 'clsx';
import { setCookies } from '../../services/Auth/index';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import constants from '../../constants/index';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: '-200px',
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '8px 2px 3px rgba(0,0,0,0.5)',
        color: '#333',
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `5px solid ${theme.palette.primary.main}`
    },
    up: {
        animation: `$up 1.5s ${theme.transitions.easing.easeIn}`,
        animationFillMode: 'forwards'
    },
    down: {
        animation: `$down 1.5s ${theme.transitions.easing.easeOut}`,
        animationFillMode: 'forwards'
    },
    "@keyframes up": {
        "0%": {
            transform: 'translateY(200px)'
        },
        "100%": {
            transform: 'translateY(-200px)'
        }
    },
    "@keyframes down": {
        "0%": {
            transform: 'translateY(-200px)'
        },
        "100%": {
            transform: 'translateY(200px)'
        }
    },
    button: {
        backgroundColor: '#f89f19',
        minWidth: '100px'
    },
    link: {
        color: '#f89f19'
    }
}));

export default function Cookies({ up }) {
    const classes = useStyles({ props: { up } });
    const [show, setShow] = useState(up);

    const changeCookies = () => {
        setCookies();
        setShow(false);
    }

    return <div className={clsx(classes.root, { [classes.up]: show }, { [classes.down]: !show })}>
        <Typography>
            Nós usamos cookies para entender como o nosso site pode melhorar a sua experiência.
            Isso inclui armazenamento de dados pessoais para personalização de conteúdo.
            Continuando a utilizar o nosso site, você aceita com a nossa política revisada de 
            uso dos cookies {<a className={classes.link} href={constants.privacidade}>Política de Privacidade</a>}.
        </Typography>
        <Button onClick={() => changeCookies()} className={classes.button}>Eu aceito</Button>
    </div>
}
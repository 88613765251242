import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(10)
    },
}));

export default function Loading() {
    const classes = useStyles();
    return (
        <CircularProgress className={classes.progress} color="primary"/>
    );
}
import React from 'react';
import Section from '../Section/index';
import Grid from '@material-ui/core/Grid';
import Contact from '../Contact/index';
import Typography from '@material-ui/core/Typography';

import { FaMapMarkedAlt } from 'react-icons/fa';
import empresa from '../../assets/images/empresa.jpg';
import ceo from '../../assets/images/ceo.jpg';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
    title: {
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    subTitle: {
        color: theme.palette.primary.dark,
        textAlign: 'left',
        marginBottom: theme.spacing(2)
    },
    textField: {
        margin: theme.spacing(2),
        width: '95%'
    },
    image: {
        maxWidth: '64%',
        height: 'auto',
        padding: theme.spacing(1)
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    wrapperColumn: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    img: {
        width: '62%',
        height: 'auto'
    }
}));

export default function Quotation() {

    const classes = useStyles();
    return (
        <Section dark>
            <Typography className={classes.title} variant="h5">Faça-nos uma visita</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <div className={classes.wrapperColumn}>
                        <img className={classes.image} src={ceo} alt="Foto do dono da empresa" />
                        <p className={classes.title}>Júnio Marcos Andrade de Sousa Matos, fundador da JM Andrade</p>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div className={classes.wrapperColumn}>
                        <img className={classes.img} src={empresa} alt="Foto da fachada da empresa JM Andrade" />
                        <p className={classes.title}><FaMapMarkedAlt /> Rua T-51 Qd. L-27 Lt. 01-A Nrº 24 Casa 02 Setor Marista Goiânia-GO – Brasil CEP 74.150-160</p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Contact />
                </Grid>
            </Grid>
        </Section >
    );
}

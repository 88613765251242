import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Location from '../Location/index';
import Header from '../Header/index';
import Comments from '../Comments/index';
import avatar from '../../assets/images/avatar.png';
import constants from '../../constants/index';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Loading from '../Loading/index';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import news from '../../assets/images/news2.jpg';
import axios from '../../axios';

const useStyles = makeStyles(theme => createStyles({
    root: {
        padding: `${theme.spacing(10)}px ${theme.spacing(3)}px`
    },
    spacing: {
        marginBottom: 12
    },
    paragraph: {
        fontSize: 12
    },
    individualSectionAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    authorSection: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignContent: 'center'
    },
    image: {
        position: 'relative',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2),
        background: 'white'
    },
    img: {
        width: '100%',
        height: 'auto'
    },
    imageSection: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2)
    },
    postImage: {
        maxWidth: '750px',
        height: 'auto'
    },
    paper: {
        padding: theme.spacing(2)
    }
}));

function News({ history }) {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(null);
    const [id, setId] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        let path = history.location.pathname;
        path = path.split('/');
        const index = path[path.length - 1];
        setId(index);
        fetchArticle();
    }, []);

    async function fetchArticle() {
        setLoading(true);
        try {
            let path = history.location.pathname;
            path = path.split('/');
            const index = path[path.length - 1];
            const response = await axios.get(`/api/index.php/article/${index}`);
            setLoading(false);
            setArticle(response.data[0]);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <>
            {loading&& <Loading />}
            {article && <Header title={article.title} img={news} />}
            <Location />
            <div className={classes.root}>
                <br />
                {
                    article && <Grid container spacing={3}>
                        <Grid item xs={12}>

                            <Paper className={classes.paper} elevation={0} component='article'>
                                <div className={classes.authorSection}>
                                    <div className={clsx(classes.individualSectionAlign, classes.spacing)}>
                                    <img src="https://jmandrade.com.br/static/media/avatar.png" className={classes.image} alt={'foto de perfil'} />
                                        <p>Por <b>{article.author_name}</b></p>
                                    </div>
                                    <div>
                                        <p className={classes.individualSectionAlign}><CalendarIcon fontSize="small" />&nbsp;&nbsp;{article.created_at}</p>
                                    </div>
                                </div>
                                <div className={classes.imageSection}>
                                    {
                                        article.img && <img src={`${constants.baseUrl}/${article.img}`} alt={article.title} className={classes.img} />
                                    }
                                </div>
                                {
                                    article && article.content.split('\n').map((p, index) => {
                                        return <p key={index}>{p}</p>
                                    })
                                }
                                {
                                    id && <Comments id={id} />
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </div>
        </>
    )
}

export default withRouter(News);

import React, { useState } from 'react'
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Section from '../Section/index'
import Typography from '@material-ui/core/Typography'
import { FaHome, FaCarAlt, FaHeart, FaMoneyBill, FaShip, FaPlane, FaSuitcaseRolling, FaUser, FaHeartbeat, FaHardHat, FaMedal, FaBus, FaGlassMartini, FaBuilding, FaUsersCog, FaMotorcycle, FaSun, FaLaptop, FaTractor, FaTree } from 'react-icons/fa'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import constants from '../../constants/index'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => createStyles({
  title: {
    textAlign: 'center'
  },
  card: {
    width: '100%',
    margin: theme.spacing(2),
    cursor: 'pointer'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  partners: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  logo: {
    fontSize: 30,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginBottom: theme.spacing(2)
  },
  link: {
    textDecoration: 'none'
  },
  titleSection: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
    textAlign: 'center'
  }
}))

const skills = [
  {
    title: 'Seguro Residencial',
    icon: FaHome,
    url: constants.seguros.residencial
  },
  {
    title: 'Seguro Auto',
    icon: FaCarAlt,
    url: constants.seguros.auto
  },
  {
    title: 'Seguro de Vida',
    icon: FaHeart,
    url: constants.seguros.vida
  },
  {
    title: 'Seguro de Patrimônio',
    icon: FaMoneyBill,
    url: constants.seguros.patrimonio
  },
  {
    title: 'Seguro de Embarcações',
    icon: FaShip,
    url: constants.seguros.embarcacoes
  },
  {
    title: 'Seguro de Aeronaves',
    icon: FaPlane,
    url: constants.seguros.aeronaves
  },
  {
    title: 'Seguro de Viagens',
    icon: FaSuitcaseRolling,
    url: constants.seguros.viagens
  },
  {
    title: 'Seguro de R. Civil',
    icon: FaUser,
    url: constants.seguros.civil
  },
  {
    title: 'Seguro de Saúde',
    icon: FaHeartbeat,
    url: constants.seguros.saude
  },
  {
    title: 'Seguro de Garantia',
    icon: FaMedal,
    url: constants.seguros.garantia
  },
  {
    title: 'Seguro de Engenharia',
    icon: FaHardHat,
    url: constants.seguros.engenharia
  },
  {
    title: 'Seguro de Transportes',
    icon: FaBus,
    url: constants.seguros.transportes
  },
  {
    title: 'Seguro de Eventos',
    icon: FaGlassMartini,
    url: constants.seguros.eventos
  },
  {
    title: 'Seguro de Condomínio',
    icon: FaBuilding,
    url: constants.seguros.condominio
  },
  {
    title: 'Seguro Empresarial',
    icon: FaUsersCog,
    url: constants.seguros.empresarial
  },
  {
    title: 'Seguro para bikes e motos',
    icon: FaMotorcycle,
    url: constants.seguros.motocicletas
  },
  {
    title: 'Seguros para sistemas fotovoltaicos ',
    icon: FaSun,
    url: constants.seguros.energiaSolar
  },
  {
    title: 'Seguros para riscos cibernéticos',
    icon: FaLaptop,
    url: constants.seguros.cibernetico
  },
  {
    title: 'Seguro Rural',
    icon: FaTractor,
    url: constants.seguros.rural
  },
  {
    title: 'Riscos para riscos ambientais',
    icon: FaTree,
    url: constants.seguros.ambiental
  }
]

const initialState = []
for (let i = 0; i < skills.length; i++) {
  initialState[i] = false
}

export default function Skills () {
  const classes = useStyles()

  return (
    <Section constrastBackground>
      <Typography variant='h6' className={classes.titleSection}>Nossas Especialidades</Typography>
      <Grid container spacing={3}>
        {
                    skills && skills.map((skill, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3}><Link to={skill.url} className={classes.link}>
                          <Tooltip
                            placement='bottom'
                            title='Clique para acessar'
                          >
                            <Card key={index} className={classes.card} elevation={2}>
                              <CardContent className={classes.cardContent}>
                                <skill.icon className={classes.logo} />
                                {skill.title}
                              </CardContent>
                            </Card>
                          </Tooltip>
                        </Link>
                        </Grid>
                      )
                    })
                }
      </Grid>
    </Section>
  )
}

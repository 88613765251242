import React, {useEffect, useState} from 'react';
import {getCurrentUser, setCurrentUser, getCookies} from './services/Auth';
import CssBaseline from '@material-ui/core/CssBaseline';
import Cookies from './components/Cookies/index';
import Routes from './components/Routes/index';

function App() {
  const [showCookies, setShowCookies] = useState(false);

  const checkCookies = () => {
    setTimeout(() => {
      const cookiesSetted = getCookies();
      if (!cookiesSetted) {
        setShowCookies(true);
      }
    }, 2000);
  }


  useEffect(() => {
    try {
      checkCookies();
      getCurrentUser();
    } catch(error){
      setCurrentUser('user', null);
    }
  }, []);
  return (
    <>
      <Routes />
      {showCookies && <Cookies up={showCookies ? true : false} />}
      <CssBaseline />
    </>
  );
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FaQuoteLeft } from 'react-icons/fa';
import { FaQuoteRight } from 'react-icons/fa';

const useStyles = makeStyles(theme => createStyles({
    root: {
        zIndex: 1,
        width: '100%',
        minHeight: '50vh'
    },
    wrapper: {
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '50vh',
        backgroundColor: 'rgba(35, 58, 92, 0.7)',
        padding: theme.spacing(3),
        color: theme.palette.primary.contrastText
    },
    icon: {
        display: 'block',
        fontSize: 48
    },
    quote: {
        fontSize: 36
    },
    grow: {
        flexGrow: 1
    },
    align: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    author: {
        marginTop: theme.spacing(3),
        fontSize: 24,
        fontStyle: 'italic'
    }
}));

function Blockquote({ quote, author, img, x, y }) {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: `${x} ${y}`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'center',
            backgroundAttachment: 'fixed'
        }}>
            <div className={classes.wrapper}>
                <FaQuoteLeft className={classes.icon} />
                <blockquote className={classes.quote}>
                    {quote}
                </blockquote>
                <div className={classes.align}>
                    <FaQuoteRight className={classes.icon} />
                </div>
                <div className={classes.align}>
                    <p className={classes.author}>- {author}</p>
                </div>
            </div>
        </div>
    );
}

Blockquote.propTypes = {
    quote: PropTypes.string,
    author: PropTypes.string,
    img: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.string,
}

Blockquote.defaultProps = {
    x: 'center',
    y: 'center'
}

export default Blockquote;
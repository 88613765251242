import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import constants from "../../constants/index";
import Section from "../Section/index";
import image from "../../assets/images/consultation_image.jpg";
import img0 from "../../assets/images/whychoose_0.jpg";
import img1 from "../../assets/images/whychoose_1.jpg";
import img2 from "../../assets/images/whychoose_2.jpg";
import img3 from "../../assets/images/whychoose_3.jpg";
import img4 from "../../assets/images/whychoose_4.jpg";
import qr from "../../assets/images/qr.jpg";
import { Link } from "react-router-dom";

const images = [img0, img1, img2, img3, img4];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: theme.spacing(10),
    },
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.main,
    },
    link: {
      textDecoration: "none",
    },
    image: {
      maxWidth: "100%",
      height: "auto",
      padding: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    leftSection: {
      padding: theme.spacing(2),
    },
    center: {
      display: "flex",
      justifyContent: "center",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

const panels = [
  {
    title: "Criatividade",
    content:
      "Sempre renovando e inovando para bem atender seus segurados em busca de soluções e alternativas viáveis para contratação do melhor seguro.",
  },
  {
    title: "Suporte via Whatsapp",
    content:
      "Para maior agilidade no atendimento disponibiliza um canal direto com fone (62) 99932-7070 Whatsapp como também divulgação e interação nas redes sociais. ",
  },
  {
    title: "Competência",
    content:
      "Profissionalismo, compromisso, seriedade e respeito aos seus clientes e parceiros.",
  },
  {
    title: "Valores",
    content: "Responsabilidades em suas ações e conduta ética.",
  },
  {
    title: "Experiência",
    content: "Mais de duas décadas de atuação no segmento de seguros.",
  },
];

export default function WhyChoose() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Section>
      <Typography variant="h6" className={classes.title}>
        Por quê escolher a JM Andrade ?
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} className={classes.leftSection}>
          {panels &&
            panels.map((panel, index) => {
              return (
                <ExpansionPanel
                  expanded={expanded === `panel${index}`}
                  key={index}
                  onChange={handleChange(`panel${index}`)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                  >
                    <Typography className={classes.heading}>
                      {panel.title}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <img
                        className={classes.image}
                        src={`${images[index]}`}
                        alt={panel.title}
                      />
                      {panel.content}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link className={classes.link} to={constants.institucional}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                className={classes.button}
              >
                Saiba Mais
              </Button>
            </Link>
            <Link className={classes.link} to={constants.c6bank}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                className={classes.button}
                style={{ marginTop: '8px' }}
              >
                Consultoria Empresarial C6 Bank
              </Button>
            </Link>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Escaneie aqui</Typography>
            <img
              src={qr}
              alt="Meu qr code"
              style={{
                display: "block",
                padding: "10px",
                width: "200px",
                height: "auto",
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.center}>
            <img
              src={image}
              alt="Empresa JM Andrade Fachada"
              className={classes.image}
            />
          </div>
        </Grid>
      </Grid>
    </Section>
  );
}

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => createStyles({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.dark
        //margin: `${theme.spacing(2)}px 0`
    },
    paper: {
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.primary.dark,
        //marginBottom: 15
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
    },
    icon: {
        paddingTop: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    }
}));

function Location({ history }) {
    const locationPath = history.location.pathname + '';
    const path = locationPath.split('/');
    path.splice(0, 1);
    path.splice(0, 0, '/');

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <Breadcrumbs className={classes.link} separator={ <NavigateNextIcon fontSize="small" />} aria-label="breadcrumbs">
                    {
                        path.map((location, index) => {
                            return (index + 1) < path.length ? <Link key={index} className={classes.link} to={location === '/' ? '/' : `/${location.charAt(0).toUpperCase() + location.slice(1)}`}>
                                {location === '/' ? <HomeIcon className={classes.icon} fontSize="small"/> : location.charAt(0).toUpperCase() + location.slice(1)}
                            </Link> : <p className={classes.link} key={index}>{location.charAt(0).toUpperCase() + location.slice(1)}</p>
                        })
                    }
                </Breadcrumbs>
            </Paper>
        </div>
    );
}

export default withRouter(Location);
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => createStyles({
    header: {
        zIndex: 1,
    },
    wrapper: {
        zIndex: 2,
        width: '100%',
        height: '100%',
        padding: `${theme.spacing(10)}px ${theme.spacing(2)}px`,
        backgroundColor: 'rgba(35, 58, 92, 0.7)',
        color: theme.palette.primary.contrastText,
        textAlign: 'center'
    },
    styledHr: {
        border: `5px solid ${theme.palette.primary.contrastText}`,
        maxWidth: '35px'
    },
    spacing: {
        marginTop: theme.spacing(10)
    }
}));

function Header({ title, img, x, y, spacing }) {
    const classes = useStyles();
    return (
        <header className={clsx(classes.header, {[classes.spacing]: spacing})} style={{
                backgroundImage: `url(${img})`, 
                backgroundPosition: `${x} ${y}`, 
                backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat', 
                backgroundOrigin: 'center',
            }}>
            <div className={classes.wrapper}>
                <Typography variant="h3">{title}</Typography> 
                <hr className={classes.styledHr}/>
            </div>
        </header>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    img: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.string
}

Header.defaultProps = {
    x: 'center',
    y: 'center'
}

export default Header;
import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: `${theme.spacing(10)}px ${theme.spacing(3)}px`
  },
  constrastBackground: {
    backgroundColor: `${theme.palette.primary.contrastText}!important`
  },
  noFullHeight: {
    minHeight: "0px"
  },
  noSpacing: {
    padding: `0px`
  },
  dark: {
    backgroundColor: theme.palette.primary.light
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0)
  }
}));


export default function Section(props) {
  const classes = useStyles();
  return (
    <div className={clsx(
        classes.root, 
        {[classes.dark]: props.dark}, 
        {[classes.center]: props.center}, 
        {[classes.noSpacing]: props.noSpacing}, 
        {[classes.noFullHeight]: props.noFullHeight},
        {[classes.constrastBackground]: props.constrastBackground})}
    >
      <Container component='section'>
        {props.children}
      </Container>
    </div>
  );
}

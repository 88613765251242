import React from 'react';
import './style.css';
import Helmet from 'react-helmet';
import Section from '../Section/index';
import Grid from '@material-ui/core/Grid';
import Header from '../Header/index';

export default function GenericSkill(props) {
    return (
        <>
            <Helmet>
                <title>{`JM Andrade - ${props.title}`}</title>
                <meta name="description" content="A JM ANDRADE é especialista também em seguros profissionais para diversas áreas."/>
            </Helmet>
            <Header title={props.title} img={props.img} />
            <Section>
                <Grid container spacing={3}>
                    {props.html}
                    {
                        props.html ? <div dangerouslySetInnerHTML={{ __html: props.children }} /> :props.children
                    }
                </Grid>
            </Section>
        </>
    );
}

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import constants from '../../constants/index';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => createStyles({
    card: {
        width: '100%',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    paragraph: {
        fontSize: 14,
        color: '#333',
        marginBottom: 12
    },
    title: {
        color: '#333',
        marginBottom: 20
    },
    alignActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        alignContent: 'center'
    },
    timestamp: {
        fontSize: 9
    },
    chip: {
        margin: theme.spacing(1)
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    image: {
        width: '100%',
        height: 'auto'
    }
}))

export default function NewsCard({ data }) {
    const classes = useStyles();
    return (
        <Card className={classes.card} component='article'>
            <CardContent>
                <Typography className={classes.timeStamp}>{data.timestamp}</Typography>
                <Typography className={classes.title} variant="h4">{data.title}</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        {
                            data && data.img && <img className={classes.image} src={`${constants.baseUrl}/${data.img}`} alt="Imagem do conteudo" />
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                        { data && <p>{data.content.split('\n')[0]}</p> }
                        { data && <p>{data.content.split('\n')[1]}</p> }
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className={classes.alignActions}>
                <Chip label={data.category} color="primary" />
                <Link className={classes.link} to={`${constants.blog}/${data.id}`}><Button size="small" color="primary">Leia Mais</Button></Link>
            </CardActions>
        </Card>
    );
}

export default {
    inicio: '/',
    painel: '/painel',
    blog: '/blog',
    institucional: '/institucional',
    documentoscopia: '/documentoscopia',
    treinamento: '/treinamento',
    seguros: {
        auto: '/seguros/auto',
        residencial: '/seguros/residencial',
        vida: '/seguros/vida',
        patrimonio: '/seguros/patrimonio',
        embarcacoes: '/seguros/embarcacoes',
        aeronaves: '/seguros/aeronaves',
        viagens: '/seguros/viagens',
        civil: '/seguros/civil',
        saude: '/seguros/saude',
        garantia: '/seguros/garantia',
        engenharia: '/seguros/engenharia',
        transportes: '/seguros/transportes',
        eventos: '/seguros/eventos',
        condominio: '/seguros/condominio',
        empresarial: '/seguros/empresarial',
        motocicletas: '/seguros/motocicletas',
        energiaSolar: '/seguros/energia-solar',
        cibernetico: '/seguros/cibernetico',
        ambiental: '/seguros/ambiental',
        rural: '/seguros/rural'
    },
    seguro: '/seguros',
    legislacao: '/legislacao',
    consultoria: '/consultoria',
    c6bank: '/c6bank',
    autenticar: '/autenticar',
    registrar: '/registrar',
    alunos_materiais: '/painel/materiais',
    recuperarSenha: '/autenticar/recuperar',
    administracao: '/admin',
    privacidade: '/privacidade',
    larguraMenuPainelAlunos: 240,
    download: 'https://jmandrade.com.br/download',
    baseUrl: 'https://jmandrade.com.br',
    adminEmail: 'junio@jmandrade.com.br',
    paginationLimit: 6
}

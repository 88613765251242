import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NewsCard from '../NewsCard/index';
import constants from '../../constants/index';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import axios from '../../axios';
import Loading from '../Loading/index';
import Section from '../Section/index';
import Loadable from 'react-loadable';

const LoadableAlert = Loadable({
    loader: () => import('../Alert/index'),
    loading: () => Loading
});

const useStyles = makeStyles(theme => createStyles({
    title: {
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        textAlign: 'center'
    },
    news: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    link: {
        marginTop: theme.spacing(5),
        textDecoration: 'none'
    }
}));

export default function BestArticles() {
    const classes = useStyles();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        fetchArticles();
    }, []);

    async function fetchArticles() {
        setLoading(true);
        try {
            const response = await axios.get('/api/index.php/article?page=1');
            setLoading(false);
            console.log(response.data.data);
            setArticles(response.data.data.splice(0, 1));
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Section dark>
            <Typography variant="h5" className={classes.title}>Último Artigo</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {
                        loading && <Loading />
                    }
                    {
                        articles && articles.length === 0 && <LoadableAlert message="Não existem artigos no momento" />
                    }
                </Grid>
                {
                    articles && articles.length > 0 && articles.map((article, index) => {
                        return (
                            <Grid key={index} item xs={12}>
                                <NewsCard data={article} />
                            </Grid>
                        )
                    })
                }
            </Grid>
            {
                articles && articles.length > 0 && <Grid item xs={12}>
                    <div className={classes.buttonWrapper}>
                        <Link className={classes.link} to={constants.blog}><Button variant="contained" color="primary" size="large">Mais Artigos</Button></Link>
                    </div>
                </Grid>
            }
        </Section>
    );
}

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import author from '../../assets/images/author.jpg';
import avatar from '../../assets/images/avatar.png';
import Comment from '../Comment/index';
import CustomMessage from '../CustomMessage/index';
import TextField from '@material-ui/core/TextField';
import { getCurrentUser } from '../../services/Auth';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import axios from '../../axios';

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    image: {
        position: 'relative',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2)
    },
    cardContentAlign: {
        display: 'flex'
    },
    commentAlign: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'flex-start'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        margin: theme.spacing(1)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    }
}));

export default function Comments({ id }) {
    const [commentContent, setCommentContent] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [reload, setReload] = useState(false);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        loadComments();
    }, []);

    async function loadComments() {
        try {
            const response = await axios.get(`/api/index.php/article/${id}/comment`);
            console.log(response);
            setComments(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    function handleChangeComment(event) {
        setCommentContent(event.target.value);
    }

    function handleResetInput() {
        setCommentContent('');
    }

    async function insertComment() {
        setSuccess(false);
        const data = {
            content: commentContent,
            author: getCurrentUser().id,
            authorName: getCurrentUser().name,
            article: id
        }

        try {
            console.log(data);
            await axios.post(`api/index.php/article/${id}`, { ...data });
            setComments([...comments, { ...data, created_at: 'agora', image: author, author_name: getCurrentUser().name }]);
            setReload(false);
            setSuccess("Comentário postado !");
        } catch (error) {
            setError("Não foi possível comentar !");
        }
    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            {
                success && <CustomMessage type="success" message={success} handleClose={() => setSuccess(false)} open={success} />
            }
            {
                error && <CustomMessage type="error" message={error} handleClose={() => setError(false)} open={error} />
            }
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{comments.length} Comentários</Typography>
                    <hr style={{ border: '0.5px solid rgba(0, 0, 0, 0.25)', marginBottom: '15px' }} />
                    <div className={classes.commentAlign}>
                        <img src="https://jmandrade.com.br/static/media/avatar.png" className={classes.image} alt={'foto de perfil'} />
                        <TextField
                            id="standard-multiline-flexible"
                            multiline
                            placeholder="Insira um comentário"
                            rowsMax="4"
                            value={commentContent}
                            onChange={e => handleChangeComment(e)}
                            className={classes.textField}
                            margin="normal"
                        />
                    </div>
                    <div className={classes.actions}>
                        <Button className={classes.button} size="medium" onClick={handleResetInput}>Cancelar</Button>
                        <Button className={classes.button} size="medium" variant="contained" color="primary" disabled={commentContent.length === 0} onClick={insertComment}>Comentar</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {
                        comments && comments.length > 0 && comments.map((comment, index) => {
                            return <Comment key={index} comment={comment} id={id} reload={setReload} />
                        })
                    }
                </Grid>
            </Grid>
        </div>
    );
}

const initialState = {
    openDrawerStudentsDashboard: false,
    currentStudentDashboardPage: 0,
    courseIndexLoad: 0,
    user: null
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case 'OPEN_DRAWER_DASHBOARD': {
            console.log('OPEN_DRAWER_DASHBOARD');
            return {
                ...state,
                openDrawerStudentsDashboard: true,
                currentStudentDashboardPage: action.currentPage
            }
        }
        case 'CLOSE_DRAWER_DASHBOARD': {
            console.log('CLOSE_DRAWER_DASHBOARD');
            return {
                ...state,
                openDrawerStudentsDashboard: false
            }
        }
        case 'CURRENT_STUDENT_DASHBOARD_PAGE': {
            console.log('CURRENT_STUDENT_DASHBOARD_PAGE');
            return {
                ...state,
                currentStudentDashboardPage: action.page
            }
        }
        case 'LOAD_COURSE_INDEX': {
            console.log('LOAD_COURSE_INDEX');
            return {
                ...state,
                courseIndexLoad: action.index
            }   
        }
        case 'SET_AUTHENTICATED_USER': {
            return {
                ...state,
                user: action.user
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default reducer;